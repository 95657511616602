import { findLast, isNil, last } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Marker, Popup, Polyline } from "react-leaflet";
import Leaflet from "leaflet";
import "leaflet.heat";
import { formatters, getColorForPercentage, getDistance } from "../utils";
import Style from "./Map.module.scss";
import YellowIcon from "./icons/marker-yellow.svg";
import GreenIcon from "./icons/marker-green.svg";
import BlueIcon from "./icons/marker-blue.svg";
import RedIcon from "./icons/marker-red.svg";
import moment from "moment";
import ls from "local-storage";
import Map from "./Map";

const CarMap = ({
  onClick,
  className = "",
  noControl = false,
  width = null,
  height = null,
  data = [],
  rawData,
  fullSize = false,
  fallbackCenter = null,
  forceCenter = null,
  style = {},
  setMap: setMapProp,
}) => {
  const [map, setMap] = useState();
  const [showMarkers, setShowMarkers] = useState(ls("showMarkers") ?? true);
  const [showLastRoute, setShowLastRoute] = useState(
    ls("showLastRoute") ?? false
  );
  const [showHeatmap, setShowHeatmap] = useState(ls("showHeatmap") ?? true);
  const linesRef = useRef({});
  const linesCoordsRef = useRef({});
  const markersRef = useRef({});
  const speedMinMax = useMemo(() => {
    const minMax = { min: 0, max: 0 };

    data.forEach(({ payload }) => {
      if (!payload.Speed) {
        return;
      }

      const absSpeed = Math.abs(parseInt(payload.Speed.value));
      if (absSpeed < minMax.min) {
        minMax.min = absSpeed;
      }
      if (absSpeed > minMax.max) {
        minMax.max = absSpeed;
      }
    });

    return minMax;
  }, [data]);

  const getSpeedPercent = useCallback(
    (speed) => {
      if (isNil(speed)) {
        return 0;
      }

      const absSpeed = Math.abs(parseInt(speed));

      return absSpeed / ((speedMinMax.max - speedMinMax.min) / 100);
    },
    [speedMinMax.max, speedMinMax.min]
  );

  const onToggleMarkers = useCallback(
    () =>
      setShowMarkers((prev) => {
        ls("showMarkers", !prev);
        return !prev;
      }),
    []
  );

  const onToggleLastRoute = useCallback(
    () =>
      setShowLastRoute((prev) => {
        ls("showLastRoute", !prev);
        return !prev;
      }),
    []
  );

  const onToggleHeatmap = useCallback(
    () =>
      setShowHeatmap((prev) => {
        ls("showHeatmap", !prev);
        return !prev;
      }),
    []
  );


  const heatmapData = useMemo(() => {
    return rawData.map((coord) => [
      parseFloat(coord.lat),
      parseFloat(coord.lng),
    ]);
  }, [rawData]);

  const polygonData = useMemo(() => {
    const newData = [];

    let route = 0;
    let markerIndex = 1;
    let prevDistances = {};
    data.forEach(({ value, time, hasCharging, payload, today }, index) => {
      if (!today) {
        return;
      }
      const prevData = data[index - 1];
      const nextData = data[index + 1];
      const newPoint =
        !prevData || moment(time).diff(prevData.time, "minute") > 5;
      const lastPoint =
        !nextData || moment(nextData.time).diff(time, "minute") > 5;

      if (newPoint) {
        route++;
      }

      const coords = hasCharging
        ? [last(value), last(value)]
        : [...(newPoint ? [] : prevData?.value), ...value];
      const markers = [];
      const distance = getDistance(coords);
      prevDistances[route] = (prevDistances[route] ?? 0) + distance;

      if ((index === 0 || newPoint) && !hasCharging) {
        markers.push({
          color: "green",
          index: markerIndex++,
          coord: coords[0],
        });
      }

      markers.push({
        color: hasCharging ? "yellow" : lastPoint ? "red" : "blue",
        index: markerIndex++,
        coord: last(coords),
      });

      const routeId = `route-${route}`;
      const newDatum = {
        payload: {
          ...payload,
          Distance: {
            ...formatters.abbreviation(distance, ["m", "km"]),
          },
          TotalDistance: {
            ...formatters.abbreviation(prevDistances[route], ["m", "km"]),
          },
        },
        time,
        id: routeId,
        percent: getSpeedPercent(payload.Speed?.value),
        markers,
        color: hasCharging
          ? "#3388ff"
          : getColorForPercentage(getSpeedPercent(payload.Speed?.value)),
        coords,
      };

      newData.push(newDatum);
    });

    const lastMarker = last(newData[newData.length - 1]?.markers);
    if (lastMarker) {
      lastMarker.isLast = true;
    }
    return newData;
  }, [data, getSpeedPercent]);

  const lastRoute = useMemo(() => {
    const lastRouteId = polygonData.reduce((acc, current) => {
      if (current.id !== acc) {
        return current.id;
      }

      return acc;
    }, "");
    const lastRoute = polygonData.filter(
      (polygonDatum) => polygonDatum.id === lastRouteId
    );

    return lastRoute;
  }, [polygonData]);

  const usedPolygonData = useMemo(() => {
    if (showLastRoute) {
      return lastRoute;
    }

    return polygonData;
  }, [lastRoute, polygonData, showLastRoute]);

  const addLineToRef = useCallback(
    (routeId, index, line, coords, background = false) => {
      const lineGroupKey = background ? "bg" : "fg";
      if (!linesRef.current[routeId]?.[lineGroupKey]) {
        linesRef.current[routeId] = {
          ...(linesRef.current[routeId] ?? {}),
          [lineGroupKey]: {},
        };
      }
      linesRef.current[routeId][lineGroupKey][index] = line;

      const lineKey = JSON.stringify(coords);
      linesCoordsRef.current[lineKey] = line;
    },
    []
  );

  const addMarkerToRef = useCallback((routeId, index, line) => {
    if (!markersRef.current[routeId]) {
      markersRef.current[routeId] = {};
    }
    markersRef.current[routeId][index] = line;
  }, []);

  const selectRoute = useCallback((routeId, hover = false, click = false) => {
    Object.keys(linesRef.current).forEach((key) => {
      Object.values(linesRef.current[key]).forEach((lineGroup) => {
        Object.values(lineGroup).forEach((line) => {
          line?.setStyle({ opacity: !hover || key === routeId ? 1 : 0 });
          line?.bringToFront();
        });
      });

      Object.values(markersRef.current[key]).forEach((marker) => {
        marker?.setOpacity(!hover || key === routeId ? 1 : 0);
      });
    });
  }, []);

  const getIcon = useCallback((index, color, isLast = false) => {
    let icon = BlueIcon;
    if (color === "red") {
      icon = RedIcon;
    } else if (color === "green") {
      icon = GreenIcon;
    } else if (color === "yellow") {
      icon = YellowIcon;
    }
    return new Leaflet.DivIcon({
      className: `${Style.marker} ${isLast ? Style.markerLast : ""}`,
      html: `<img class="${Style.markerIcon}" src="${icon}"/>
              ${index !== null
          ? `<div class="${Style.markerLabel}">${index}</div>`
          : ""
        }`,
      iconSize: isLast ? [40, 40] : [32, 32],
      iconAnchor: isLast ? [20, 40] : [16, 32],
      popupAnchor: [0, -30],
    });
  }, []);

  const lastDatum = useMemo(() => {
    const lastPolygonDatum = last(usedPolygonData);

    if (lastPolygonDatum) {
      return lastPolygonDatum;
    }

    const findAllDatum = findLast(data, ({ today }) => !today);

    if (findAllDatum) {
      return {
        end: findAllDatum.value,
      };
    }

    return undefined;
  }, [data, usedPolygonData]);

  const center = useMemo(() => {
    return forceCenter?.length === 2
      ? { lat: forceCenter[0], lng: forceCenter[1] }
      : last(lastDatum?.coords) ?? fallbackCenter;
  }, [fallbackCenter, forceCenter, lastDatum?.coords]);


  return (
    <div
      onClick={() => !fullSize && onClick?.(map)}
      className={`${Style.Container} ${fullSize ? Style.FullScreen : ""
        } ${className}`}
      style={{ width, height, ...style }}
    >
      <Map
        whenCreated={(map) => {
          setMap(map);
          setMapProp(map);
        }}
        center={center}
        zoom={13}
        noControl={noControl}
        doubleClickZoom={false}
        heatMapData={fullSize && showHeatmap ? heatmapData : []}
        controlButtons={{
          close: { action: onClick, label: "Close" },
          toggleHeatmap: {
            action: onToggleHeatmap,
            label: showHeatmap ? "Hide heatmap" : "Show heatmap",
          },
          toggleMarkers: {
            action: onToggleMarkers,
            label: showMarkers ? "Hide markers" : "Show markers",
          },
          toggleLastRoute: {
            action: onToggleLastRoute,
            label: showLastRoute
              ? "Show all routes in last 24 hours"
              : "Show only last route",
          },
        }}
      >
        {fullSize ? (
          <>
            {usedPolygonData?.map(({ coords, id: routeId }, index) => {
              return (
                <>
                  {coords?.length > 1 ? (
                    <Polyline
                      pathOptions={{
                        color: "#fff",
                        fill: false,
                      }}
                      ref={(ref) =>
                        addLineToRef(routeId, index, ref, coords, true)
                      }
                      className={`${Style.line} ${routeId}`}
                      eventHandlers={{}}
                      positions={coords}
                      weight={12}
                    />
                  ) : null}
                </>
              );
            })}
            {usedPolygonData?.map(
              (
                { coords, color, payload, time, markers, id: routeId },
                index
              ) => {
                return (
                  <>
                    {coords?.length > 1 ? (
                      <Polyline
                        ref={(ref) => addLineToRef(routeId, index, ref, coords)}
                        pathOptions={{
                          color: color,
                        }}
                        className={`${Style.line} ${routeId}`}
                        positions={coords}
                        weight={9}
                      />
                    ) : null}
                    {showMarkers &&
                      markers.length > 0 &&
                      markers.map((marker) => (
                        <Marker
                          ref={(ref) =>
                            addMarkerToRef(routeId, marker.index, ref)
                          }
                          zIndexOffset={200}
                          position={marker.coord}
                          icon={getIcon(
                            marker.index,
                            marker.color,
                            marker.isLast
                          )}
                          eventHandlers={{
                            mouseover: () => selectRoute(routeId, true),
                            mouseout: () => selectRoute(routeId, false),
                          }}
                        >
                          <Popup>
                            <div class={Style.marketPopupTitle}>
                              Location at {time}
                            </div>
                            {Object.entries(payload).map(
                              ([key, { value, appendix }]) => (
                                <div className={Style.markerPopupDetail}>
                                  <b>{key}: </b>
                                  {value}
                                  {!isNil(value) && appendix
                                    ? ` ${appendix}`
                                    : ""}
                                </div>
                              )
                            )}
                          </Popup>
                        </Marker>
                      ))}
                  </>
                );
              }
            )}
          </>
        ) : (
          <Marker
            position={center}
            icon={getIcon(null, last(lastDatum?.markers)?.color)}
          />
        )}
      </Map>
    </div>
  );
};

export default CarMap;
