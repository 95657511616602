import { useEffect, useRef } from "react";

export const usePrevious = (value, watchIf = null) => {
  const ref = useRef();

  useEffect(() => {
    if (watchIf === null || !watchIf?.includes(value)) {
      ref.current = value;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return ref.current;
};
