import {
  debounce,
  findLast,
  isNil,
  last,
  templateSettings,
  uniqBy,
} from "lodash";
import { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import Style from "../App.module.scss";
import { ENABLED_PAGES, getSiteUrl } from "../config";
import useTemplates, { parseTemplateRow } from "../hooks/use-templates";
import {
  getChartData,
  getPageUrl,
  isDatumEnableInChart,
  isLocalhost,
  isTrue,
} from "../utils";
import Icon from "./Icon";
import ActionChart, { chartColors } from "./ActionChart";
import Loader from "./Loader";
import LottieIcon from "./LottieIcon";
import CarMap from "./CarMap";
import { usePrevious } from "../hooks/use-previous";

const convertColorsFromToTemplate = (color) => {
  const gradient = color.match?.(/linear-gradient\((\d+deg,\s*)?(.*)\)$/)?.[2]?.match?.(/(rgba?\(([^\)]+)\)\s+(\d+(\w|%))|[a-zA-Z]+)/g);

  if (!gradient) {
    return `linear-gradient(${color} 10vh, #282c34 50vh)`;
  }

  const gradients = gradient.map((item) => {
    const lastIndex = item.lastIndexOf(' ');

    return [item.substr(0, lastIndex), item.substr(lastIndex + 1)];

  });


  return `linear-gradient(${gradients[0][0]} 10vh, ${gradients[1][0]} 30vh, #282c34 50vh)`;
}

const mapCommandState = (value, previous) => {
  const commandStatesMap = {
    executed: value,
    failed: value,
    "executing-hidden": previous,
  };

  return commandStatesMap[value] ?? "";
};

const TIMER = 60;
function ActionRun({
  runner,
  isLoggedIn,
  token,
  credentials,
  isDone,
  fullScreen,
  setFullScreen,
}) {
  const [commandState, setCommandState] = useState(!isDone && "loading");
  const previousCommandState = usePrevious(commandState, ["executing-hidden"]);
  const [signalController, setSignalController] = useState(null);
  const [responses, setResponses] = useState(null);
  const [mapTop, setMapTop] = useState(0);
  const [map, setMap] = useState();
  const responsesRef = useRef(null);
  const templateRef = useRef(null);
  // const [templates, setTemplates] = useState([]);
  const periodicalCounter = useRef(0);
  const launch = useRef();
  const secLeft = useRef(TIMER);
  const [time, setTime] = useState(TIMER);
  const [canMoveBack, setCanMoveBack] = useState(false);
  const [runnerExpiredData, setRunnerExpiredData] = useState([]);
  const [runnerData, setRunnerData] = useState({
    data: runner?.data ?? [],
    dataMax: runner?.dataMax ?? {},
    dataMin: runner?.dataMin ?? {},
  });

  const resetState = useCallback(() => {
    setCommandState('loading');
    setResponses(null);
    responsesRef.current = null;
    periodicalCounter.current = 0;
    launch.current = undefined;
    secLeft.current = TIMER;
    setTime(TIMER);
    setCanMoveBack(false);
    setRunnerExpiredData([]);
    setRunnerData({
      data: [],
      dataMax: {},
      dataMin: {},
    })
  }, []);

  const { templates, parseTemplates } = useTemplates();
  const history = useHistory();

  const setAll = useCallback(
    (state) => {
      setCommandState(state);
      setTimeout(() => setMapTop(!fullScreen ? window.scrollY : 0), 10);
    },
    [fullScreen]
  );

  const buttons = useMemo(() => runner?.buttons ?? [], [runner?.buttons]);

  const templateColors = useMemo(() => {
    const colors = uniqBy(
      (runner?.data ?? []).filter((curr) => isDatumEnableInChart(curr)),
      "template_id"
    ).reduce((acc, curr, index) => {
      return {
        ...acc,
        [curr.template_id]: chartColors[index % chartColors.length],
      };
    }, {});

    return colors;
  }, [runner?.data]);

  const decrement = useCallback(() => {
    if (secLeft.current > 0) {
      secLeft.current--;
      setTime(secLeft.current);

      if (secLeft.current <= 0) {
        clearInterval(launch.current);
        setCanMoveBack(true);
      }
    }
  }, [secLeft]);

  useEffect(() => {
    const resize = debounce(() => setMapTop(window.scrollY), 100, {
      trailing: true,
    });

    window.addEventListener("scroll", resize);

    return () => window.removeEventListener("scroll", resize);
  }, []);

  const runAction = useCallback(
    (next = null, inBackground = false, hidden = false, counter = 0) => {
      if (
        !runner ||
        !isLoggedIn ||
        !token ||
        ["execution", "execution-background", "execution-hidden"].includes(
          commandState
        )
      ) {
        return Promise.reject();
      }

      if (commandState !== "executing") {
        setAll(
          !hidden
            ? !inBackground
              ? "executing"
              : "executing-background"
            : "executing-hidden"
        );
      }

      const controller = new AbortController();
      const signal = controller.signal;

      setSignalController(controller);

      return fetch(
        `${getSiteUrl()}api/runner/${runner.key}?async=1${inBackground
          ? `&periodicalCounter=${counter}&update=1&count=${runner?.data?.length}`
          : `${runner.periodical ? "&update=1" : ""}`
        }`,
        {
          signal,
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          ...(next ? { body: JSON.stringify({ next }) } : {}),
        }
      )
        .then((resp) => resp.json())
        .then((data) => {
          concatResponses(data.responses, !!next, {
            main: {
              status: data.status,
              statusText: data.statusText,
            },
          });

          if (data.runner.expiredData?.length) {
            setRunnerExpiredData((prev) => {
              return [...data.runner.expiredData, ...prev];
            });
          }

          if (data.runner.data?.length) {
            setRunnerData({
              data: data.runner.data,
              dataMax: data.runner.dataMax ?? {},
              dataMin: data.runner.dataMin ?? {},
            });
          }

          if (data.status === 200) {
            setAll(data.next ? "executing-next" : "executed");
          } else {
            setAll("failed");
          }

          if (!data.next) {
            startTimer(
              ...(data.runner.expiredData && data.runner.expiredData.length > 0
                ? [1, true]
                : [])
            );
          } else {
            runAction(data.next);
          }
        })
        .catch((e) => {
          console.error(e);
          responsesRef.current = null;
          setResponses(null);
          setAll("failed");
          startTimer();
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [runner]
  );
  const periodicalFetch = useCallback(
    (time = 10, hidden = false) => {
      if (secLeft.current > 0) {
        secLeft.current--;
        setTime(secLeft.current);
      } else if (secLeft.current <= 0) {
        runAction(null, true, hidden, periodicalCounter.current++);
        clearInterval(launch.current);
        secLeft.current = time;
        launch.current = setInterval(() => periodicalFetch(time, hidden), 1000);
      }
    },
    [runAction]
  );

  const startTimer = useCallback(
    (time = 10, hidden = false) => {
      if (!isTrue(runner?.single)) {
        clearInterval(launch.current);
        launch.current = setInterval(decrement, 1000);
      } else if (isTrue(runner?.periodical)) {
        clearInterval(launch.current);
        secLeft.current = time;
        launch.current = setInterval(() => periodicalFetch(time, hidden), 1000);
      }
    },
    [decrement, periodicalFetch, runner?.periodical, runner?.single]
  );

  const resetTimer = useCallback(() => {
    clearInterval(launch.current);
    secLeft.current = TIMER;
    setTime(secLeft.current);
  }, [secLeft]);

  useEffect(() => {
    return () => {
      clearInterval(launch.current);
    };
  }, []);

  const concatResponses = useCallback(
    (newResponses, shouldDo = false, spread = {}) => {
      const updatedResponses = (
        shouldDo && responsesRef.current ? responsesRef.current : []
      )
        .concat(Object.values(newResponses ?? {}))
        .map((response) => ({
          ...response,
          ...spread,
        }));
      responsesRef.current = updatedResponses;
      setResponses(updatedResponses);
    },
    []
  );

  useEffect(() => {
    if (runner && commandState === "loading") {
      parseTemplates();
      runAction();
    } else if (
      runner &&
      [
        "executing-next",
        "executing-background",
        "executing-hidden",
        "executed",
        "failed",
      ].includes(commandState)
    ) {
      parseTemplates(responses, runner.templates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commandState, parseTemplates, responses, runAction, runner]);

  const isDark = useCallback((index, template) => {
    if (index % 2) {
      return true;
    }

    return false;
  }, []);


  const dataPack = useMemo(() => {
    return {
      min: runnerData.dataMin,
      max: runnerData.dataMax,
      data: runnerData.data.filter((datum) => isTrue(datum.today)),
      dataAll: runnerExpiredData,
    };
  }, [
    runnerData.data,
    runnerData.dataMax,
    runnerData.dataMin,
    runnerExpiredData,
  ]);

  const chartData = useMemo(() => getChartData(dataPack.data), [dataPack.data]);

  const mainLevelTemplates = useMemo(
    () =>
      (runner?.templates ?? []).reduce(
        (acc, curr) => ({
          ...acc,
          [curr.id]: curr,
        }),
        {}
      ),
    [runner]
  );


  const rawData = useMemo(
    () =>
      (dataPack.dataAll ?? [])
        .concat(dataPack.data ?? [])
        .reduce((acc, datum) => {
          if (datum.formatter !== "map") {
            return acc;
          }

          const mapValues = datum.value.split(";");

          return acc.concat(
            mapValues.reduce((accCoord, mapValue) => {
              const [lat, lng] = mapValue.split(",");

              if (isNaN(parseFloat(lat)) || isNaN(parseFloat(lng))) {
                return accCoord;
              }

              return [...accCoord, { lat, lng }];
            })
          );
        }, []),
    [dataPack.data, dataPack.dataAll]
  );

  const mapData = useMemo(() => {
    const payloads = (dataPack.data ?? []).reduce((acc, datum) => {
      const templateLabel =
        mainLevelTemplates[datum.template_id]?.label ?? datum.template_id;

      const returnData = {
        ...acc,
        [datum.time]: {
          ...(acc[datum.time] ?? {}),
          [templateLabel]: parseTemplateRow({
            // TODO ide jon hogy az uj valuet is parsoljuk
            ...mainLevelTemplates[datum.template_id],
            value: datum.value,
          }),
        },
      };

      return returnData;
    }, {});

    const data = (dataPack.data ?? []).reduce((acc, datum) => {
      if (datum.formatter !== "map") {
        return acc;
      }

      const templateLabel =
        mainLevelTemplates[datum.template_id]?.label ?? datum.template_id;

      const mapValues = datum.value.split(";");

      payloads[datum.time][templateLabel].value = last(mapValues);

      return [
        ...acc,
        {
          value: mapValues.map((mapValue) => {
            const [lat, lng] = mapValue.split(",");
            return { lat, lng };
          }),
          time: datum.time,
          today: isTrue(datum.today),
          payload: {
            ...payloads[datum.time],
          },
        },
      ];
    }, []);

    const newData = [];
    data.forEach((datum, index) => {
      const nextDatum = data[index + 1];
      if (!datum?.payload?.["Charging type"]) {
        newData.push(datum);
        return;
      }

      if (!nextDatum?.payload?.["Charging type"]) {
        newData.push({
          hasCharging: true,
          ...datum,
        });
      }
    });

    return newData;
  }, [dataPack, mainLevelTemplates]);

  const fallbackCenter = useMemo(() => {
    if (mapData?.length > 0) {
      return null;
    }

    const lastAll = findLast(
      dataPack.dataAll,
      (datum) => datum.formatter === "map"
    );

    let lat, lng;
    if (lastAll) {
      [lat, lng] = last(lastAll.value?.split(";") ?? []).split(",");
    }

    return lat && lng ? { lat, lng } : null;
  }, [dataPack.dataAll, mapData?.length]);

  const onClickMap = useCallback(
    (map) => {
      setMapTop(!fullScreen ? window.scrollY : 0);
      setFullScreen(!fullScreen);
      setTimeout(() => {
        const { value: center } = last(mapData);
        center && map.setView(last(center), 13);
      }, 200);
    },
    [fullScreen, mapData, setFullScreen]
  );

  const [forceLocation, setForceLocation] = useState();

  const renderTemplates = useCallback(
    (templateArray, counter = { index: 0 }) => {
      return (templateArray ?? templates).map((template) => {
        if (
          template.hidden ||
          (template.formatter === "map" && mapData?.length <= 0)
        ) {
          return null;
        }
        counter.index++;

        return (
          <div
            key={template.id}
            className={`${Style.templateWrapper} ${isDark(counter.index, template) ? Style.dark : ""
              }`}
          >
            <div
              className={`${Style.template} ${template.level > 0
                ? `${Style.childTemplate} ${Style[`level-${template.level}`]}`
                : ""
                } ${Style[template.formatter]}`}
              style={{ color: templateColors[template?.templateId] ?? null }}
              key={template.label}
            >
              {template.formatter === "map" ? (
                <CarMap
                  setMap={setMap}
                  onClick={onClickMap}
                  noControl={!fullScreen}
                  style={{ top: fullScreen ? mapTop : null }}
                  fullSize={fullScreen}
                  data={mapData}
                  forceCenter={!fullScreen && forceLocation}
                  fallbackCenter={fallbackCenter}
                  rawData={rawData}
                />
              ) : (
                <>
                  <span className={Style.label}>{template.label}</span>
                  <span className={Style.value}>
                    {template.value}
                    <span className={Style.appendix}>
                      {!isNil(template.value) && template.appendix
                        ? ` ${template.appendix}`
                        : ""}
                    </span>
                  </span>
                </>
              )}
            </div>
            {template.children?.length > 0
              ? renderTemplates(template.children, counter)
              : null}
          </div>
        );
      });
    },
    [
      fallbackCenter,
      forceLocation,
      fullScreen,
      isDark,
      mapData,
      mapTop,
      onClickMap,
      rawData,
      templateColors,
      templates,
    ]
  );

  const mapTemplates = useMemo(() => {
    return templates.filter((template) => template.formatter === "map");
  }, [templates]);

  const templateBackground = useMemo(() => {
    return !runner?.background ? undefined : convertColorsFromToTemplate(runner.background);
  }, [runner]);


  useEffect(() => {
    if (!templateRef?.current) {
      return;
    }

    const templateBgRef = templateRef?.current?.parentNode.parentNode.parentNode;
    templateBgRef.style.background = templateBackground;


    const navigations = templateRef?.current?.previousSibling?.querySelectorAll('a');

    navigations?.forEach((nav) => {
      nav.style.color = runner?.foreground;
    });

    return () => {
      templateBgRef.style.background = '';
      navigations?.forEach((nav) => {
        nav.style.color = '';
      });
    }
  }, [runner?.foreground, templateBackground]);

  const onHoverChart = useCallback(
    debounce(
      (payload) => {
        if (!payload) {
          setForceLocation(null);
          return;
        }

        const mapFormatter = mapTemplates?.[0]?.templateId;
        const location = last(
          payload?.[0]?.payload?.[mapFormatter]?.split(";")
        )?.split(",");
        if (location?.length === 2) {
          setForceLocation(location);
        }
      },
      100,
      { trailing: true }
    ),
    [mapTemplates]
  );

  const renderedTemplates = useMemo(() => renderTemplates(), [renderTemplates]);

  if (
    commandState === "executing" &&
    !isDone &&
    !isLocalhost() &&
    !isTrue(runner.single)
  ) {
    return (
      <Redirect
        to={getPageUrl(
          ENABLED_PAGES.actions,
          credentials,
          `${runner.key}/done`
        )}
      />
    );
  }

  if (canMoveBack || !commandState) {
    return <Redirect to={getPageUrl(ENABLED_PAGES.actions, credentials)} />;
  }

  if (!isLoggedIn) {
    return <Loader />;
  }

  return (
    <div className={`${Style.Action}`} ref={templateRef}>
      {runner ? (
        <h2 style={{ color: runner?.foreground }}>
          <span>
            {["executing-next", "executing-background", "executing"].includes(
              commandState
            )
              ? "Executing"
              : ""}{" "}
            "
          </span>
          {runner.groupName ? runner.groupName + " / " : ""}
          {runner.name}
          <span>" {mapCommandState(commandState, previousCommandState)}</span>
        </h2>
      ) : (
        <h2 style={{ color: runner?.foreground }}>Loading</h2>
      )}
      <Icon icon={runner?.icon} background='transparent' foreground={runner?.foreground} noClick onlyIcon />
      {[
        "executing-next",
        "executing-background",
        "executing",
        "loading",
      ].includes(commandState) && <Loader />}
      {commandState === "executed" ||
        (commandState === "executing-hidden" &&
          previousCommandState === "executed") ? (
        <LottieIcon name="Success" keepLastFrame />
      ) : null}
      {commandState === "failed" ||
        (commandState === "executing-hidden" &&
          previousCommandState === "failed") ? (
        <LottieIcon name="Failed" keepLastFrame />
      ) : null}

      <div className={Style.templates}>{renderedTemplates}</div>

      {chartData.length &&
        ["executing-background", "executing-hidden", "executed"].includes(
          commandState
        ) ? (
        <div className={Style.chart}>
          <ActionChart
            data={chartData}
            dataMax={dataPack.max}
            dataMin={dataPack.min}
            templates={runner.templates}
            color={templateColors}
            onHover={onHoverChart}
          />
        </div>
      ) : null}

      <div className={Style.buttons}>
        <Link
          to={getPageUrl(ENABLED_PAGES.actions, credentials)}
          onClick={() => signalController?.abort?.()}
        >
          {["executed", "failed", "executing-hidden"].includes(commandState)
            ? "Back to actions" + (isTrue(runner.single) ? "" : `in ${time}s`)
            : "Cancel"}
        </Link>
        {["executed", "failed", "executing-hidden"].includes(commandState) && (
          <button
            style={{
              opacity: isTrue(runner.periodical) ? 1 : time / TIMER,
            }}
            onClick={() => {
              resetTimer();
              setAll("loading");
            }}
          >
            Run again {!isTrue(runner.periodical) ? "" : `in ${time}s`}
          </button>
        )}
      </div>
      {buttons.length > 0 ? <div className={`${Style.buttons} ${Style.integrated}`}>
        {buttons.map((button, index) => {
          return <button key={index}
            style={{ animation: 'none', background: button.background, border: `1px solid ${button.foreground}`, color: button.foreground }}
            onClick={(e) => {
              if (button.single !== "1") {
                e.preventDefault();
              } else {
                resetState();
                history.push(
                  getPageUrl(ENABLED_PAGES.actions, credentials, button.key)
                );
              }
            }}
            onDoubleClick={(e) => {
              if (button.single !== "0") {
                e.preventDefault();
              } else {
                resetState();
                history.push(
                  getPageUrl(ENABLED_PAGES.actions, credentials, button.key)
                );
              }
            }}
            to={getPageUrl(ENABLED_PAGES.actions, credentials, button.key)}
          >
            Run "{button.name}"
          </button>
        })}
      </div> : null}
    </div>
  );
}

export default ActionRun;
