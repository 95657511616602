import Style from "./App.module.scss";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Apps from "./components/Apps";
import Actions from "./components/Actions";
import Backups from "./components/Backups";
import { useState, useEffect, useCallback, useMemo } from "react";
import ActionRun from "./components/ActionRun";
import { ENABLED_PAGES, getSiteUrl } from "./config";
import sha1 from "sha1";
import ls from "local-storage";
import LottieIcon from "./components/LottieIcon";
import { getPagePath, getPageUrl } from "./utils";
import PullToRefresh from "react-simple-pull-to-refresh";
import LoaderWhite from "./components/Loader-White";
import { NavTab } from "react-router-tabs";
import { omit } from "lodash";

function App() {
  // const [credentials, setCredentials] = useState({ username: null, key: null });
  const [token, setToken] = useState(ls("token") ?? "");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [runners, setRunnersState] = useState(ls("runners"));
  const [apps, setApps] = useState(ls("apps"));
  const [backups, setBackups] = useState(ls("backups"));
  const [fullScreen, setIsFullScreen] = useState(false);

  const queryString = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );

  const setRunners = useCallback((newRunners) => {
    const newRunnerData = {};
    const newRunnerDataWithStat = {};
    const newRunnerStats = {};
    (newRunners ?? []).forEach((newRunner) => {
      newRunnerData[newRunner.id] = omit(newRunner, "data");
      newRunnerDataWithStat[newRunner.id] = newRunnerData[newRunner.id];

      const newRunnerStat = newRunner?.data;
      newRunnerStats[newRunner.id] =
        newRunnerStat ?? ls(`stats-${newRunner.id}`);

      newRunnerDataWithStat[newRunner.id].data =
        newRunnerStats[newRunner.id] ?? [];
      if (newRunnerStat?.length) {
        ls(`stats-${newRunner.id}`, newRunnerStats[newRunner.id]);
      }
    });
    setRunnersState((prev) => {
      const newState = {
        ...prev,
        ...newRunnerDataWithStat,
      };

      ls("runners", newState);

      return newState;
    });
  }, []);

  const handleToken = useCallback((data) => {
    if(!(data instanceof Error) || (typeof data === 'object' && 'status' in data && data.status === 401)) {
      setIsLoggedIn(data?.token ? true : false);
      setToken(data?.token ?? null);
      ls("token", data?.token || "");
    }
  }, []);

  const credentials = useMemo(() => {
    const checkRegex = new RegExp(
      `^/auth/(?<username>[a-zA-Z0-9]+)/(?<key>[a-zA-Z0-9]+)(?<path>/?$|/(${Object.values(
        ENABLED_PAGES
      ).join("|")}).*$)`
    );
    const checkCredentials = window.location.pathname.match(checkRegex)?.groups;
    if (queryString.get("username") && queryString.get("key")) {
      return {
        username: queryString.get("username"),
        key: queryString.get("key"),
        path: window.location.pathname,
      };
    } else if (checkCredentials?.username && checkCredentials?.key) {
      return {
        username: checkCredentials?.username,
        key: checkCredentials?.key,
        path: checkCredentials?.path,
      };
    }

    return { path: window.location.pathname };
  }, [queryString]);

  const setFullScreen = useCallback((state) => {
    if (state) {
      document.body.classList.add(Style.fullScreen);
    } else {
      document.body.classList.remove(Style.fullScreen);
    }
    setTimeout(() => {
      setIsFullScreen(state);
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }, []);

  const fetchAuth = useCallback(() => {
    return fetch(`${getSiteUrl()}api/auth`, {
      method: "POST",
      body: JSON.stringify({
        username: credentials.username,
        key: sha1(credentials.key),
      }),
    })
      .then((resp) => resp.json())
      .then(handleToken)
      .catch(handleToken);
  }, [credentials.key, credentials.username, handleToken]);

  const fetchRunners = useCallback(
    (id = false, getChart = false) => {
      return fetch(
        `${getSiteUrl()}api/runners?${new URLSearchParams({
          ...(getChart ? { chart: 1 } : {}),
          ...(id ? { id } : {}),
        })}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((resp) => resp.json())
        .then((data) => {
          if (data.status === 200 && data.runners) {
            setIsLoggedIn(true);
            setRunners(data.runners ?? []);
          } else {
            handleToken();
          }

          return data.runners;
        })
        .catch(handleToken);
    },
    [handleToken, setRunners, token]
  );

  const fetchApps = useCallback(() => {
    return fetch(`${getSiteUrl()}api/apps`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.status === 200 && data.apps) {
          setIsLoggedIn(true);
          setApps(data.apps ?? []);
          ls("apps", data.apps ?? []);
        } else {
          handleToken();
        }
      })
      .catch(handleToken);
  }, [handleToken, token]);

  const fetchBackups = useCallback(() => {
    return fetch(`${getSiteUrl()}api/backups`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.status === 200 && data.backups) {
          setIsLoggedIn(true);
          setBackups(data.backups ?? []);
          ls("backups", data.backups ?? []);
        } else {
          handleToken();
        }
      })
      .catch(handleToken);
  }, [handleToken, token]);

  useEffect(() => {
    ls("lastFetched", null);
    if (credentials?.username && credentials?.key && !token) {
      fetchAuth();
    } else if (token) {
      fetchRunners().then((data) => {
        (data ?? []).forEach((datum) => fetchRunners(datum.id, true));
      });
      fetchApps();
      fetchBackups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div className={Style.App}>
      {!isLoggedIn && (
        <>
          <h1>Login required</h1>
          <LottieIcon name="Failed" keepLastFrame />
        </>
      )}
      <PullToRefresh
        onRefresh={() => setTimeout(() => window.location.reload(), 1000)}
        className={Style.PTR}
        isPullable={!fullScreen}
        pullingContent={
          <div style={{ textAlign: "center", padding: 10 }}>
            Pull to refresh
          </div>
        }
        refreshingContent={
          <LoaderWhite style={{ width: 50, height: 50, margin: "5px auto" }} />
        }
      >
        {isLoggedIn && (
          <Router>
            <div className={Style.titles}>
              <NavTab
                activeClassName={Style.active}
                to={getPageUrl(ENABLED_PAGES.actions, credentials)}
                exact
              >
                Actions
              </NavTab>
              <NavTab
                activeClassName={Style.active}
                to={getPageUrl(ENABLED_PAGES.apps, credentials)}
              >
                Apps
              </NavTab>
              <NavTab
                activeClassName={Style.active}
                allowClickOnActive
                to={getPageUrl(ENABLED_PAGES.backups, credentials)}
              >
                Backups
              </NavTab>
            </div>
            <Switch>
              <Route
                path={getPagePath(
                  ENABLED_PAGES.actions,
                  credentials,
                  ":action/:state?"
                )}
                render={(props) => {
                  const selectedRunner = Object.values(runners ?? {}).find(
                    (runner) => runner.key === props.match?.params?.action
                  );

                  if (runners && !selectedRunner) {
                    return (
                      <Redirect
                        to={getPageUrl(ENABLED_PAGES.actions, credentials)}
                      />
                    );
                  }

                  return (
                    <ActionRun
                      runner={selectedRunner}
                      isLoggedIn={isLoggedIn}
                      isDone={props.match?.params?.state === "done"}
                      token={token}
                      credentials={credentials}
                      fullScreen={fullScreen}
                      setFullScreen={setFullScreen}
                    />
                  );
                }}
              />
              <Route path={getPagePath(ENABLED_PAGES.apps, credentials)}>
                <Apps isLoggedIn={isLoggedIn} apps={apps} token={token} />
              </Route>
              <Route path={getPagePath(ENABLED_PAGES.backups, credentials)}>
                <Backups
                  backups={backups}
                  isLoggedIn={isLoggedIn}
                  credentials={credentials}
                  token={token}
                />
              </Route>
              <Route
                path={getPagePath("", credentials).concat(
                  getPagePath(ENABLED_PAGES.actions, credentials)
                )}
              >
                <Actions
                  runners={runners}
                  isLoggedIn={isLoggedIn}
                  credentials={credentials}
                  token={token}
                />
              </Route>
            </Switch>
          </Router>
        )}
      </PullToRefresh>
    </div>
  );
}

export default App;
