import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MapContainer, TileLayer, useMapEvents } from "react-leaflet";
import Leaflet from "leaflet";
import "leaflet.heat";
import Style from "./Map.module.scss";

const controlsInstance = Leaflet.Control.extend({
  options: {
    position: "topright",
  },

  onAdd: function (map) {
    const container = Leaflet.DomUtil.create("div");
    container.setAttribute("class", "leaflet-bar leaflet-control");

    Object.values(this.options.controlButtons ?? {}).forEach(
      (controlButton) => {
        const button = Leaflet.DomUtil.create("a");
        button.href = "#";
        button.title = controlButton.label;
        button.innerHTML = controlButton.label;
        button.classList.add(Style.button);

        button.onclick = (e) => {
          e.preventDefault();
          controlButton.action?.(map);
        };
        container.append(button);
      }
    );

    return container;
  },
});

function MapController({ noControl, center, heatmapData, controlButtons }) {
  const lastCenter = useRef();
  const heatmapLayer = useRef();
  const map = useMapEvents({
    locationfound: (location) => {
      console.log("location found:", location);
    },
  });
  const controlsContainer = useMemo(
    () =>
      new controlsInstance({
        position: "topright",
        controlButtons,
      }),
    [controlButtons]
  );

  useEffect(() => {
    if (lastCenter.current !== JSON.stringify(center)) {
      lastCenter.current = JSON.stringify(center);
      map.setView(center);
    }
  }, [center, map]);

  useEffect(() => {
    const method = noControl ? "disable" : "enable";
    map.scrollWheelZoom[method]?.();
    map.dragging[method]?.();
    map.zoomControl[method]?.();

    if (!noControl) {
      map.addControl(controlsContainer);
    } else {
      map.removeControl(controlsContainer);
    }

    return () => {
      map?.removeControl(controlsContainer);
    };
  }, [noControl, map, controlsContainer]);

  useEffect(() => {
    if (heatmapData?.length) {
      heatmapLayer.current?.removeFrom(map);
      // heatmapLayer.current = Leaflet.heatLayer(heatmapData, {
      //   max: 2,
      //   radius: 20,
      //   blur: 20,
      // }).addTo(map);
    } else {
      heatmapLayer.current?.removeFrom(map);
    }
  }, [heatmapData, map]);

  return null;
}

const Map = ({
  controlButtons,
  noControl = false,
  whenCreated,
  center,
  heatMapData,
  children,
}) => {
  const [, setMapState] = useState();

  const setMap = useCallback(
    (mapStateValue) => {
      setMapState(mapStateValue);
      whenCreated?.(mapStateValue);
    },
    [whenCreated]
  );

  return (
    <MapContainer
      whenCreated={setMap}
      center={center}
      zoom={13}
      doubleClickZoom={false}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {children}
      <MapController
        controlButtons={controlButtons}
        noControl={noControl}
        heatmapData={heatMapData}
        center={center}
      />
    </MapContainer>
  );
};

export default Map;
