import { debounce, first, get as lGet, isNil, last, set } from "lodash";
import moment from "moment";
import { parse } from "node-html-parser";
import haversine from "haversine-distance";

export const isLocalhost = () => window.location.hostname === "localhost";

export const getPageUrl = (page, credentials, path = "") => {
  const authPath =
    credentials?.username && credentials?.key
      ? `/auth/${credentials?.username}/${credentials?.key}`
      : "";
  return `${authPath}/${page}${path ? "/" : ""}${path ?? ""}`;
};

export const getPagePath = (page, credentials, path = "") => {
  const authPath =
    credentials?.username && credentials?.key
      ? `/auth/${credentials?.username}/${credentials?.key}`
      : "";
  const normalPath = `/${page}${path ? "/" : ""}${path ?? ""}`;
  return authPath ? [`${authPath}${normalPath}`, normalPath] : [normalPath];
};

const mappedCallbacks = {
  "@html": (_, found) => (found?.keyLast ? "." : "") + "@html",
  "@json": (_, found) => (found?.keyLast ? "." : "") + "@json",
  "@record": (_, found) => (found?.keyLast ? "." : "") + "@record",
  "@last": (obj, found) => {
    return (found?.keyLast ? "." : "") + last(Object.keys(obj));
  },
  "@first": (obj, found) => {
    return (found?.keyLast ? "." : "") + first(Object.keys(obj));
  },
};
const mappedKeys = Object.keys(mappedCallbacks);

export const logger = {
  ...(console ?? {}),
  show: (title, content, operation = "log") => {
    operation = typeof console[operation] === "function" ? operation : "log";
    console[operation]?.("");
    title &&
      console[operation]?.(`%c ----- ${title} ----- start`, "color: green;");
    console[operation]?.({ content });
    title && console[operation]?.(`%c ----- ${title} ----- end`, "color: red;");
  },
};

export const get = (object, key) => {
  const check = { object: JSON.parse(JSON.stringify(object)) };
  let replacedKey = key;
  mappedKeys.forEach((mappedKey) => {
    const mappedRegex = new RegExp(
      `(?<rule>(^(?<keyFirst>${mappedKey})|.(?<keyLast>${mappedKey}))(\\((?<params>[^\\)]+)\\))?)`,
      "m"
    );
    const foundKey = replacedKey.match(mappedRegex)?.groups;

    if (foundKey?.rule) {
      const mainKey = replacedKey.split(foundKey?.rule)?.[0];
      let mainObj = mainKey ? lGet(check.object, mainKey) : check.object;

      if (mainObj && ["@html", "@json", "@record"].includes(mappedKey)) {
        let content;
        if (
          typeof mainObj === "string" &&
          mappedKey === "@html" &&
          foundKey?.params
        ) {
          const html = parse(mainObj);
          content = {
            [mappedKey]: html?.querySelector(foundKey?.params)?.innerHTML,
          };
        } else if (typeof mainObj === "string" && mappedKey === "@json") {
          try {
            content = { [mappedKey]: JSON.parse(mainObj) };
          } catch (err) {
            content = undefined;
          }
        } else if (Array.isArray(mainObj) && mappedKey === "@record") {
          content = {
            [mappedKey]: mainObj.reduce((acc, loopObj) => {
              const [key, value, add, operation] =
                foundKey?.params?.split(/\s*,\s*/);
              const newKey = lGet(loopObj, key);

              if (isNil(newKey)) {
                return acc;
              }

              const newObj = {
                [newKey]: lGet(loopObj, value),
              };

              const newAdd = parseFloat(lGet(loopObj, add));
              const newValue = parseFloat(newObj[newKey]);
              if (
                !isNaN(newAdd) &&
                !isNaN(newValue) &&
                ["+", "-", "/", "*"].includes(operation)
              ) {
                switch (operation) {
                  case "+":
                    newObj[newKey] = newValue + newAdd;
                    break;
                  case "-":
                    newObj[newKey] = newValue - newAdd;
                    break;
                  case "/":
                    newObj[newKey] = newValue / newAdd;
                    break;
                  case "*":
                    newObj[newKey] = newValue * newAdd;
                    break;
                  default:
                    break;
                }
              }

              return { ...acc, ...newObj };
            }, {}),
          };
        }

        if (!mainKey) {
          check.object = content;
        } else {
          set(check.object, mainKey, content);
        }

        replacedKey = replacedKey.replace(
          mappedRegex,
          `${mappedCallbacks[mappedKey](mainObj, foundKey)}`
        );
      } else if (mainObj && typeof mainObj === "object") {
        replacedKey = replacedKey.replace(
          mappedRegex,
          `${mappedCallbacks[mappedKey](mainObj, foundKey)}`
        );
      }
    }
  });

  return lGet(check.object, replacedKey);
};

export const getWithHtml = (object, key) => {
  const parts = key.split("@html.", 2);

  const currentObject = !parts[0] ? object : get(object, parts[0]);
  const html = parse(currentObject);

  // @html.span.main-price
  // megcsinalni, hogy megadhassunk script taget, amit parseol jsonkent
  // kiiratni teljes tombot gridkent, hogy az osszes sorat kiirathassuk ez is egy template fajta
  // html parsert bevinni cronba is
  // a cronnal lekezelni a dollarjelet a szambol

  return html?.querySelector(parts[1])?.childNodes?.[0].rawText;
};

window.moment = moment;

const momentFormatter = (value, [format]) => {
  if (!moment(value).isValid()) {
    return { value: "No" };
  }

  if (value > "2500-01-01 00:00:00") {
    return { value: "Yes" };
  }

  return { value: moment(value).format(format) };
};

const currencyFormatter = (value, [currency], isChart = false) => {
  const chartOptions = {
    maximumFractionDigits: 0,
  };
  return {
    value: new Intl.NumberFormat("hu-HU", {
      currency,
      style: "currency",
      ...(isChart ? chartOptions : {}),
    }).format(value),
  };
};

const percentFormatter = (value) => {
  return {
    value: new Intl.NumberFormat("hu-HU", {
      style: "percent",
    }).format(value / 100),
  };
};

const yesNoFormatter = (givenValue = 0, _, template) => {
  const valueIf = template?.valueIf;
  const valueElse = template?.valueElse;
  let validValue = 'No';
  
  if(valueIf && [valueIf, 'Yes', 1].includes(givenValue)) {
    validValue = valueIf;
  } else if(valueElse && [valueElse, 'No', 0].includes(givenValue)) {
    validValue = valueElse;
  } else if(isTrue(givenValue)) {
    validValue = 'Yes';
  }

  return { sourceValue: givenValue, value: validValue, appendix: '' };
}

const abbreviationFormatter = (givenValue = 0, suffixes) =>
  abbreviation(givenValue, 1, suffixes);

const abbreviation = (
  givenValue = 0,
  precision = 1,
  suffixes = ["", "K", "M"]
) => {
  if (!isFinite(givenValue)) {
    return { value: givenValue };
  }

  const value = parseFloat(givenValue);
  const flooredValue = Math.floor(value) || 0;
  const suffixNum = Math.floor(
    (`${flooredValue}`.length - (flooredValue < 0 ? 2 : 1)) / 3
  );
  let shortValue =
    suffixNum !== 0
      ? parseFloat((value / Math.pow(1000, suffixNum)).toPrecision(3))
      : value;
  if (shortValue % 1 !== 0) {
    shortValue = shortValue.toFixed(
      shortValue < 10 ? precision + 1 : precision
    );
  }
  const suffix =
    (typeof suffixes === "string"
      ? suffixes
      : suffixes?.[suffixNum] ?? suffixes?.[0]) ?? "";
  return { sourceValue: givenValue, value: shortValue, appendix: suffix };
};

export const formatters = {
  abbreviation: abbreviationFormatter,
  moment: momentFormatter,
  currency: currencyFormatter,
  percent: percentFormatter,
  yesNo: yesNoFormatter
};

export const isTrue = (value, checkOpposite = false) => {
  const checkValue = value && value !== "0" && value !== "false";

  if (checkOpposite) {
    return !checkValue;
  }

  return !!checkValue;
};

export const shortHexToLongHex = (value) => {
  if (value?.match(/^#[0-9a-fA-F]{3}$/)) {
    return value.replace(/[0-9a-fA-F]{1}/g, (m) => m + m);
  }

  return value;
};

let isMobileWidthValue = window.innerWidth <= 1000;
let resizeSet = false;
export const isMobileWidth = () => {
  if (!resizeSet) {
    window.addEventListener(
      "resize",
      debounce(() => {
        isMobileWidthValue = window.innerWidth <= 1000;
      }, 100)
    );

    resizeSet = true;
  }

  return isMobileWidthValue;
};

export const roundToNextDecimal = (valueProp) => {
  const value = parseFloat(valueProp);
  if (isNaN(value)) {
    return;
  }
  return (
    Math.ceil(value / Math.pow(10, `${Math.ceil(value / 10)}`.length)) *
    Math.pow(10, `${Math.ceil(value / 10)}`.length)
  );
};

export const findKey = (object, findKey) => {
  return Object.keys(object).find((key) => new RegExp(`^${findKey}`).test(key));
};

export const getColorForPercentage = (perc) => {
  let r,
    g,
    b = 0;
  if (perc < 50) {
    r = 255;
    g = Math.round(5.1 * perc);
  } else {
    g = 255;
    r = Math.round(510 - 5.1 * perc);
  }
  const h = r * 0x10000 + g * 0x100 + b * 0x1;
  return "#" + ("000000" + h.toString(16)).slice(-6);
};

export const getDistance = (coords) => {
  return (coords ?? []).reduce((acc, curr, index) => {
    return (
      acc + (index < coords.length - 1 ? haversine(curr, coords[index + 1]) : 0)
    );
  }, 0);
};

export const isDatumEnableInChart = (datum) => {
  return (
    !isNaN(parseFloat(datum.value)) &&
    // datum.formatter !== "map" &&
    isTrue(datum.today)
  );
};

export const getChartData = (data) => {
  return (data ?? []).filter((datum) => isDatumEnableInChart(datum));
};

export const getMapData = (data) => {
  const mapData = {
    all: [],
    today: [],
  };

  (data ?? []).forEach((datum) => {
    if (datum.value !== "" && datum.formatter === "map") {
      mapData[isTrue(datum.today) ? "today" : "all"].push(datum);
    }
  });

  return mapData;
};

const getCryptoData = async (statement) => {
  if (!statement) {
    window.document.body.focus();
    statement = await navigator.clipboard.readText();
  }

  const parse = (key, data) => {
    let newData = data;
    switch (key) {
      case "Spent":
      case "Fee":
      case "Bought": {
        newData =
          data.match(
            /(\+|-)?\s*(?<currency>[^\s0-9,.]+)\s*(?<amount>[0-9,.]+)/m
          )?.groups ?? {};

        break;
      }
      case "Exchange rate": {
        newData =
          data.match(
            /1\s(?<currency>[^\s0-9,.]+)\s=\s(?<currency2>[^\s0-9,.]+)\s*(?<amount>[0-9,.]+)/m
          )?.groups ?? {};
        break;
      }
      default: {
      }
    }

    if (newData.amount) {
      newData.amount = parseFloat(newData.amount.replace(/[^0-9.]/g, "") || 0);
    } else {
      newData.amount = 0;
    }

    return newData;
  };

  let lastKey = "Date";
  let canParse = false;
  const data = statement.split(/\n\s*/).reduce((acc, curr, index) => {
    if (!canParse && curr !== "Status") {
      return { ...acc, [lastKey]: [...(acc[lastKey] ?? []), curr] };
    }

    canParse = true;

    if (index % 2 === 0) {
      lastKey = curr;
      return acc;
    }
    return { ...acc, [lastKey]: parse(lastKey, curr) };
  }, {});

  // console.log("Debug 1", data);
  if (data.Date) {
    data.Date = data.Date.join(" ");
  }
  data.Total = {
    currency: data.Bought.currency,
    currency2: data.Fee.currency,
    amount: data.Fee.amount + data.Bought.amount,
  };

  return { data, original: statement };
};

// eslint-disable-next-line no-unused-vars
const setupCryptoData = () => {
  const element = document.querySelector(".transaction-btn-container button");

  element.addEventListener("click", () => {
    getCryptoData().then(({ data: statementData, original }) => {
      console.log(statementData, original);

      console.log(
        `%c Crypto: %c ${statementData.Total.currency}`,
        "font-size: 20px; color: red",
        "font-size: 20px; color: #bada55"
      );
      ["Date", "Total", "Exchange rate", "Fee"].forEach((key) => {
        const statementDatum = statementData[key];
        // console.log("Debug 2", key, statementDatum, statementData);

        if (statementDatum) {
          console.log(
            `%c ${key}: %c ${
              typeof statementDatum === "string"
                ? statementDatum
                : statementDatum.amount
            } ${statementDatum.currency2 || statementDatum.currency || ""}`,
            "font-size: 20px; color: red",
            "font-size: 20px; color: #bada55"
          );
        } else {
          console.warn(`${key} missing`);
        }
      });
    });
  });
  element.style.color = "lime";
};

// const pids = {
//   2101: {},
//   2105: {},
// };

// //2101
// pids["2101"]["Turned off car aux 12.9v"] =
//   "7ED10336101FFFFFBC07EA10166101FFE000007EC103D6101FFFFFFFF7EB101E6101000003FF7EE037F21127ED210098DB958559A07EA210921103D063B037EC219C26482648A3FF7EB2108380149950A337ED22046E9E024700BB7EA2200000000B069007EC22950EED161214157EB220000000A001E027ED23050F43102000C87EA23072000000000007EC231312160010C7127EB233B0401333000307ED24078C014C7600197EC24C63800008100037EB24009FFF000000007ED25001501F4958F627EC2512AD00031033007ED26D20013003203E77EC26011D7E0001135C7ED27051F01000000007EC2700B3515C09017E7EC280000000003E800";

// pids["2101"]["Turned off, ignited car aux 12.9V, power 4.2kw, V 325"] =
//   "7EA10166101FFE000007ED10336101FFFFFBC07EB101E6101000003FF7EC103D6101FFFFFFFF7EA210921103B0639037EB21083801AE95FC327EC219E26482648A3FF7EA2200000000B769007ED21009A9195E6599A7EE037F21127ED22046AA5024600BB7EB220000000A001F027EC22960EF7161214157EA23072000000000007EB233B04E9324900187ED23050E43102000C87EC231312150010C7127EB24009FFF000000007ED24078C014C7600197EC24C73800008100037ED25001501F395F33D7EC2512B700031033007ED26800013003203E77EC26011D810001135C7ED27051E01000000007EC2700B3527D09017F7EC280000000003E800";

// pids["2101"]["Turned on aux 13.9"] =
//   "7EC103D6101FFFFFFFF7EA10166101FFE000007EB101E6101000003FF7ED10336101FFFFFBC07EE037F21127EC21A026482648A3FF7EB21083801A1955F367ED210099E095EF59E77EA2109211240063E037EC22CE0EFA161214157EB2200B0092D002C027ED220471A7024600BB7EA2200000000AF70347EC231312150010C7127EB233D0477361800307ED23056443102000C87EA23072000000000007EC24C73800008900037ED24078C014C7600197EB2400B7FF000000007EC2512BC00031033007ED25001501F395F76C7EC26011D840001135C7ED26A00013003303E77EC2700B3533109017F7ED27057601000000007EC280000000003E800";

// pids["2101"]["full turned off"] =
//   "7EA10166101FFE000007EA21092180360634037EA22000000007966307EA2304200000000000";
// pids["2101"]["Turned off with no power"] =
//   "7EA10166101FFE000007EA210921802C062F037EA22000000003D64007EA2306200000000000";

// pids["2101"]["correct"] =
//   "7EE037F21127EB101E6101000003FF7ED10336101FFFFFBC07EC103D6101FFFFFFFF7EA10166101FFE000007EB218C3C00339A34397ED21000295026D02F67EC21B21545264803FD7EA2109285A3B0634037EB2200DC050A0076027ED22000069003301BC7EA220000B20FCF76347EC22670F4D060405057EB2376046339BF191A7ED2305C841000000007EC230405060004CC507EA23040800000000007EB24E12605000000007ED24000000006000007EC24CB2100009200037ED25000001F40000027EC2524AC000321CB007ED26600000000000007EC26012415000119837ED2705C800000000007EC2700B61AA90D018A7EC280FA00FA003E800";

// pids["2101"]["correct charging"] =
//   "7EB101E6101000003FF7EA10166101FFE000007ED10336101FFFFFBC07EE037F21127EC103D6101FFFFFFFF7EB21083801F49348397EA2109211040063B037ED2100984E942A5A337EC219726482648A3FF7EB220000000A0023027EA2200000000B576007ED22047CA4024900BC7EC22960ECD1B14191A7EB233D0432391800007EA23072000000000007ED2305B243102000C87EC2316141A0012C5117EB2400E8FF000000007ED24078C014C7600227EC24C53800009000037ED25001E01F39447617EC25240000032192007ED267F001E003203E77EC260123D40001196E7ED2705C001000000007EC2700B5FE1009017A7EC280000000003E800";

//2105
// pids["2105"]["Turned off car soc 81%"] =
//   "7EA037F21127EC102D6105FFFFFFFF7EB037F21127ED037F21127EE037F21127EC21000000000015147EC22161213151426487EC23264800016400007EC2403E84003E811A37EC25002900000000007EC2600000000000000";

// pids["2105"]["Turned off, ignited car"] =
//   "7EB037F21127ED037F21127EA037F21127EC102D6105FFFFFFFF7EC21000000000015147EE037F21127EC22161213151426487EC23264800016400007EC2403E84003E811A67EC25002900000000007EC2600000000000000";

// pids["2105"]["Turned on"] =
//   "7EB037F21127ED037F21127EA037F21127EC102D6105FFFFFFFF7EE037F21127EC21000000000015147EC22161213151426487EC23264800015000007EC2403E84003E811A77EC25002900000000007EC2600000000000000";

// pids["2105"]["correct"] =
//   "7EB037F21127ED037F21127EA037F21127EC102D6105FFFFFFFF7EE037F21127EC21000000000006067EC220604040505152A7EC23264802015000007EC2403E84003E811BC7EC25002900000000007EC2600000000000000";

// pids["2105"]["correct charging"] =
//   "7EA037F21127ED037F21127EB037F21127EC102D6105FFFFFFFF7EE037F21127EC21000000000019197EC221A13161A1826487EC23264800016400007EC2403E84003E8119D7EC25002900000000007EC2600000000000000";

// export const isBitOn = (binary, index) => {
//   return binary[binary.length - 1 - index] === "1";
// };
// export const getPidValue = (givenKey, pidData, givenPidType, force = null) => {
//   let retValue;
//   let key = givenKey;
//   let pidType = "";
//   let pidKey = "";
//   let pidPlace = [-1, -1, -1, -1];
//   let allowConcat = true;

//   if (force) {
//     key = force.key;
//     pidType = force.pidType;
//     pidKey = force.pidKey;
//     allowConcat = force.allowConcat;
//     pidPlace = force.pidPlace;
//   } else if (key === "soc") {
//     pidType = "2105";
//     pidPlace[0] = 3;
//     pidPlace[1] = 6;
//     pidKey = "7EC2";
//   } else if (key === "soh") {
//     pidType = "2105";
//     pidPlace[0] = 3;
//     pidPlace[1] = 0;
//     pidPlace[2] = 1;
//     pidKey = "7EC2";
//   } else if (key === "availChargePower") {
//     pidType = "2105";
//     pidPlace[0] = 1;
//     pidPlace[1] = 5;
//     pidPlace[2] = 6;
//     pidKey = "7EC2";
//   } else if (key === "availDischargePower") {
//     pidType = "2105";
//     pidPlace[0] = 2;
//     pidPlace[1] = 0;
//     pidPlace[2] = 1;
//     pidKey = "7EC2";
//   } else if (key === "chargingType") {
//     pidType = "2101";
//     pidPlace[0] = 0;
//     pidPlace[1] = 5;
//     pidKey = "7EC2";
//   } else if (key === "chargingCurrent") {
//     pidType = "2101";
//     pidPlace[0] = 0;
//     pidPlace[1] = 6;
//     pidPlace[2] = 1;
//     pidPlace[3] = 0;
//     pidKey = "7EC2";
//   } else if (key === "chargingVoltage") {
//     pidType = "2101";
//     pidPlace[0] = 1;
//     pidPlace[1] = 1;
//     pidPlace[2] = 2;
//     pidKey = "7EC2";
//   } else if (key === "chargingPower") {
//     pidType = "2101";
//     pidPlace[0] = -2;
//     pidKey = "7EC2";
//   } else if (key === "auxiliary") {
//     pidType = "2101";
//     pidPlace[0] = 3;
//     pidPlace[1] = 4;
//     pidKey = "7EC2";
//   } else if (key === "gear") {
//     pidType = "2101";
//     pidPlace[0] = 0;
//     pidPlace[1] = 1;
//     pidKey = "7EA2";
//   } else if (key === "speed") {
//     pidType = "2101";
//     pidPlace[0] = 1;
//     pidPlace[1] = 3;
//     pidPlace[2] = 2;
//     pidKey = "7EA2";
//     allowConcat = false;
//   } else if (key === "minCellVoltage") {
//     pidType = "2101";
//     pidPlace[0] = 3;
//     pidPlace[1] = 0;
//     pidKey = "7EC2";
//   } else if (key === "maxCellVoltage") {
//     pidType = "2101";
//     pidPlace[0] = 2;
//     pidPlace[1] = 5;
//     pidKey = "7EC2";
//   } else if (key === "minCellVoltageNo") {
//     pidType = "2101";
//     pidPlace[0] = 3;
//     pidPlace[1] = 1;
//     pidKey = "7EC2";
//   } else if (key === "maxCellVoltageNo") {
//     pidType = "2101";
//     pidPlace[0] = 2;
//     pidPlace[1] = 6;
//     pidKey = "7EC2";
//   }

//   if (pidType !== givenPidType || pidKey === "" || pidPlace[0] === -1) {
//     return "-";
//   }

//   // debug("BODY", body);
//   // String flag = getValue(body, pidType, "7EC", true);
//   // debug("FLAG", flag);

//   const debug = () => {};
//   // const debug = console.log;

//   let value;
//   let value2;
//   let hexValue = "";
//   let hexValue2 = "";
//   debug("PID", key);
//   if (pidPlace[0] >= 0) {
//     debug("RESPONSE", pidData);
//     debug("KEY", pidKey);

//     const pidBody = pidData[pidKey];

//     if (!pidBody) {
//       return;
//     }

//     debug("BODY", pidBody);

//     debug("PART0", pidBody[0]);
//     debug("PART1", pidBody[1]);
//     debug("PART2", pidBody[2]);
//     debug("PART3", pidBody[3]);
//     debug("PART4", pidBody[4]);
//     debug("PART5", pidBody[5]);
//     debug("PART6", pidBody[6]);
//     debug("PART7", pidBody[7]);

//     let selectedPlace = pidBody[pidPlace[0]];

//     // if (selectedPlace.length() > 1)
//     // {
//     //     selectedPlace = selectedPlace.substring(0, selectedPlace.length() - 1);
//     // }

//     const selectedValue = selectedPlace[pidPlace[1]];
//     hexValue2 = "";

//     debug("Selected", selectedPlace);

//     let selectedValue2 = "";
//     if (pidPlace[2] > -1 && pidPlace[3] <= -1) {
//       selectedValue2 = selectedPlace[pidPlace[2]];
//     } else if (pidPlace[2] > -1 && pidPlace[3] > -1) {
//       selectedValue2 = selectedPlace[pidPlace[3]];
//     }

//     debug("Selected value 1", selectedValue);
//     debug("Selected value 2", selectedValue2);

//     if (allowConcat) {
//       const newHex = selectedValue.hex + (selectedValue2?.hex ?? "");
//       value = {
//         hex: newHex,
//         dec: parseInt(newHex, 16),
//         bin: parseInt(newHex, 16).toString(2).padStart(8, "0"),
//       };
//     } else {
//       value = selectedValue;
//       value2 = selectedValue2;
//     }
//     debug("Value", value);
//     debug("Value2", value2);
//   }

//   if (key === "soc") {
//     return value.dec * 0.5;
//   } else if (key === "availChargePower" || key === "availDischargePower") {
//     return value.dec * 0.01;
//   } else if (
//     key === "chargingVoltage" ||
//     key === "auxiliary" ||
//     key === "soh"
//   ) {
//     return value.dec * 0.1;
//   } else if (key === "minCellVoltage" || key === "maxCellVoltage") {
//     return value.dec * 0.02;
//   } else if (key === "minCellVoltageNo" || key === "maxCellVoltageNo") {
//     return value.dec;
//   } else if (key === "chargingType") {
//     let charging = "";
//     if (isBitOn(value.bin, 7)) {
//       if (isBitOn(value.bin, 6)) {
//         charging = "DC";
//       }
//       if (isBitOn(value.bin, 5)) {
//         charging = "AC";
//       }
//     }

//     return charging;
//   } else if (key === "chargingCurrent") {
//     return Math.abs((((value.dec + 32768) % 65536) - 32768) * 0.2);
//   } else if (key === "chargingPower") {
//     const current = getPidValue("chargingCurrent", pidData, givenPidType);
//     const voltage = getPidValue("chargingVoltage", pidData, givenPidType);

//     return Math.abs(parseFloat(voltage) * (parseFloat(current) * 0.5));
//   } else if (key === "gear") {
//     let gear = "";
//     if (isBitOn(value.bin, 0)) {
//       gear = "P";
//     }
//     if (isBitOn(value.bin, 1)) {
//       gear = "R";
//     }
//     if (isBitOn(value.bin, 2)) {
//       gear = "N";
//     }
//     if (isBitOn(value.bin, 3)) {
//       gear = "D";
//     }

//     // debug("Gear", gear);
//     return gear;
//   } else if (key === "speed") {
//     debug("SPEED O", value);
//     debug("SPEED N", value2);
//     let current_speed;
//     const newValue = value.dec + 128;
//     current_speed =
//       ((((newValue % 256) - 128) * 256 + value2.dec) / 100.0) * 1.60934;
//     debug("SPEED real", String(current_speed));
//     return current_speed;
//   } else {
//     return value.dec;
//   }
// };

// const parsePid = (pid) => {
//   const parts = pid.match(/(?<type>7E[ABCD]2(?<hex>[0-9A-F]{15}))/g);
//   const data = {};

//   if (parts) {
//     parts.forEach((part) => {
//       const header = part.substr(0, 4);
//       const index = part.substr(4, 1) - 1;
//       data[header] = data[header] ?? {};
//       data[header][index] = part
//         .substr(5)
//         .split(/(.{2})/)
//         .reduce((acc, curr) => {
//           if (!curr) {
//             return acc;
//           }
//           return [
//             ...acc,
//             {
//               hex: curr,
//               dec: parseInt(curr, 16),
//               bin: parseInt(curr, 16).toString(2).padStart(8, "0"),
//             },
//           ];
//         }, []);
//     });
//   }

//   return Object.keys(data)
//     .sort()
//     .reduce((acc, curr) => {
//       return {
//         ...acc,
//         [curr]: {
//           ...(acc[curr] ?? {}),
//           ...data[curr],
//         },
//       };
//     }, {});
// };

// const findPidProc = (type, code, pidType, pidKey) => {
//   const parsed = parsePid(code);

//   const data = {};
//   for (let i = 0; i < 8; i++) {
//     for (let j = 0; j < 7; j++) {
//       if (parsed[pidKey]?.[i]?.[j]) {
//         if (!data[`${i}`]) {
//           data[i] = {};
//         }
//         data[i][j] = getPidValue(null, parsed, pidType, {
//           key: type,
//           pidType,
//           pidPlace: [i, j],
//           pidKey,
//         });
//         // parsed[pidKey]?.[i]?.[j];
//       }
//     }
//   }

//   return data;
// };

// const test = findPidProc(
//   "auxiliary",
//   pids["2101"]["full turned off"],
//   "2101",
//   "7EA2"
// );
// console.log(test);
// const test2 = findPidProc(
//   "auxiliary",
//   "7EA10166101FFE000007EA21092181380634037EA22000000009F68307EA2304200000000000",
//   "2101",
//   "7EA2"
// );

// console.log(test2);

// Object.entries(pids).forEach(([pid, codes]) => {
//   console.log(`%c ${pid}`, "font-size: 20px; color: #bada55");

//   Object.entries(codes).forEach(([label, code]) => {
//     console.log(
//       `%c${label}: %c ${code}`,
//       "font-size: 14px; color: red",
//       "font-size: 14px; color: cyan"
//     );

//     const parsedPid = parsePid(code);
//     console.log(parsedPid);
//     console.log();
//     console.log("gear", getPidValue("gear", parsedPid, pid));
//     console.log("speed", getPidValue("speed", parsedPid, pid));
//     console.log("soc", getPidValue("soc", parsedPid, pid));
//     console.log("soh", getPidValue("soh", parsedPid, pid));
//     console.log("auxiliary", getPidValue("auxiliary", parsedPid, pid));
//     console.log("chargingType", getPidValue("chargingType", parsedPid, pid));
//     console.log("chargingPower", getPidValue("chargingPower", parsedPid, pid));
//     console.log(
//       "minCellVoltageNo",
//       getPidValue("minCellVoltageNo", parsedPid, pid)
//     );
//     console.log(
//       "minCellVoltage",
//       getPidValue("minCellVoltage", parsedPid, pid)
//     );
//     console.log(
//       "maxCellVoltageNo",
//       getPidValue("maxCellVoltageNo", parsedPid, pid)
//     );
//     console.log(
//       "maxCellVoltage",
//       getPidValue("maxCellVoltage", parsedPid, pid)
//     );
//     console.log(
//       "availChargePower",
//       getPidValue("availChargePower", parsedPid, pid)
//     );
//     console.log(
//       "availDischargePower",
//       getPidValue("availDischargePower", parsedPid, pid)
//     );
//     console.log();
//   });
// });
